import { CalendarModel, SchedulerPro } from '@bryntum/schedulerpro-thin';
import { DrawerProps } from '@mui/material/Drawer';
import Tippy, { TippyProps } from '@tippyjs/react/headless';
import axios, { CancelTokenSource } from 'axios';
import clsx from 'clsx';
import PulseButtonBase from 'components/pulse-button/base/pulse-button-base';
import PulseToggleButton from 'components/pulse-button/toggle/pulse-toggle-button';
import PulseDrawer from 'components/pulse-drawer/pulse-drawer';
import PulseTabs from 'components/pulse-tabs/pulse-tabs';
import { PulseTabsProps } from 'components/pulse-tabs/pulse-tabs-types';
import PCSSpinner, { PCSSpinnerVariants } from 'components/spinner/pcs-spinner';
import addDays from 'date-fns/addDays';
import addHours from 'date-fns/addHours';
import addMinutes from 'date-fns/addMinutes';
import format from 'date-fns/format';
import formatISO from 'date-fns/formatISO';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import isSameDay from 'date-fns/isSameDay';
import { default as isValidDate } from 'date-fns/isValid';
import parseISO from 'date-fns/parseISO';
import startOfDay from 'date-fns/startOfDay';
import { Form, Formik } from 'formik';
import { cancelSource, CancelToken, v2Endpoint } from 'pulse-api/base';
import { ALLOWED_ENV, getEnv } from 'pulse-commons/helpers';
import { ButtonVariants, Colors, PulseTabsTheme, RequestStatus } from 'pulse-commons/types';
import qs from 'qs';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import 'tippy.js/dist/tippy.css';
import { useResourcePlannerDispatch, useResourcePlannerState } from '../context/resource-planner-context';
import RPBookingModel, { RPBookingModelConfig } from '../models/rp-booking-model';
import { PulseResourcePlannerProps } from '../pulse-resource-planner-types';
import {
  Actions,
  calculateTotalHours,
  DEFAULT_MAX_HOURS,
  EditBookingType,
  validBookingDate,
} from '../reducers/resource-planner-reducer';
import { getEventColor, getEventColorCustom } from '../utils';
import ResourceOverbookedModal from './ResourceOverbooked';
import BookingDetails from './booking-details';
import BookingHistory from './booking-history';
import LinkedTasks from './booking-linked-tasks';
import { OptionType, OverBookingList } from './booking-types';
import EditFormValidationSchema from './edit-form-validation-schema';
import styles from './edit-form.module.scss';
import './edit-form.scss';
import { GROUP_BY } from './group-by';
import { REMOVE_LINK_PARAMS } from './resource-planner';

let sourceOverBooking: CancelTokenSource;

const PULSE_LOCAL_URL = getEnv(ALLOWED_ENV['PULSE_LOCAL_URL'], '');
const IS_DEV = process.env.NODE_ENV === 'development';

const CustomTippyContent = (props): ReactElement => {
  const { priority, attrs } = props;
  return (
    <div {...attrs} className={styles['edit-form__tippy']}>
      {priority === 'escalated' ? 'Click to de-escalate' : 'Click to escalate'}
    </div>
  );
};
const ProjectDetailsTippyContent = (props): ReactElement => {
  const { attrs, booking } = props;
  const { bookingId, requestedBy, job } = booking;
  const { value: jobId, jobExtension, label: title } = job;

  return (
    <div {...attrs} className={styles['edit-form__tippy']}>
      <div className={styles['edit-form__projectDetails']}>
        <div className={styles['edit-form__projectDetails--textCtnr']}>
          <span className={styles['edit-form__projectDetails--keyText']}>Booking ID:</span>
          <span>{bookingId}</span>
        </div>
        <div className={styles['edit-form__projectDetails--textCtnr']}>
          <span className={styles['edit-form__projectDetails--keyText']}>Requested By:</span>
          <span>{requestedBy[0].label}</span>
        </div>
        <div className={styles['edit-form__projectDetails--textCtnr']}>
          <span className={styles['edit-form__projectDetails--keyText']}>Project:</span>
          <a href={`passport/${jobId}`} target="_blank" rel="noreferrer" data-toggle="tooltip" title={title}>
            {title}
          </a>
        </div>
        <div className={styles['edit-form__projectDetails--textCtnr']}>
          <span className={styles['edit-form__projectDetails--keyText']}>Extension:</span>
          <span>{jobExtension}</span>
        </div>
      </div>
    </div>
  );
};

const generateTippyProps = (priority): TippyProps => {
  return {
    placement: 'bottom-end',
    render: attrs => <CustomTippyContent {...attrs} priority={priority} />,
    appendTo: document.querySelector(`.${styles['edit-form__escalatedBtn']}`) || undefined,
  };
};

const generateTippyPropsForProjectDetails = (booking): TippyProps => {
  return {
    placement: 'bottom',
    interactive: true,
    render: attrs => <ProjectDetailsTippyContent {...attrs} booking={booking} />,
    appendTo: document.querySelector(`.${styles['edit-form__projectLink']}`) || undefined,
  };
};

export interface EditFormProps {
  onSaveBookingFail?: (errors) => void;
  onSaveSuccess?: (newBooking, bookingValues, resourceId) => void;
  currentUser?: PulseResourcePlannerProps['currentUser'];
  primaryClient?: {
    clientId: string | number;
    company: string;
  };
  allOfficesIds?: string[];
  isNonChargeTime?: boolean;
  schedulerInstance: SchedulerPro | null;
}

const generateCompoundUserJobId = (values: RPBookingModel): string => {
  if (!values.bookedUser.value || !values.job?.value) {
    return '';
  }
  return `${values.bookedUser.value}-${values.job?.value}`;
};

const getResourceUserid = (values: RPBookingModel): string => {
  return `${values?.bookedUser?.value ?? ''}`;
};
/**
 * Generate Project Resource Planner for scheduler
 * It use to add to scheduler for display new project booking after booking created
 * @param bookingValues the values of booking
 */
// const generateProjectResource = (bookingValues: RPBookingModel): RPUserModel | null => {
//   const { bookedUser, job } = bookingValues;
//   if (!bookedUser.raw || !job?.raw) {
//     return null;
//   }

//   const newResource = {
//     id: generateCompoundUserJobId(bookingValues),
//     name: bookedUser.label,
//     departments: bookedUser.raw.departments,
//     defaultTimesheetActivity: bookedUser.raw.defaultTimesheetActivity,
//     isAgresso: bookedUser.raw.isAgresso,
//     maxHours: bookedUser.raw.maxHours,
//     primaryClient: bookedUser.raw.primaryClient,
//     roles: bookedUser.raw.roles,
//     job: {
//       type: 'jobs',
//       id: job.raw.jobId,
//       attributes: {
//         brandId: job.raw.brandId,
//         brandTitle: job.raw.brandTitle,
//         editMode: job.raw.editMode,
//         isAgresso: job.raw.isAgresso,
//         isNC: job.raw.isNC,
//         is_finance_manager: job.raw.is_finance_manager,
//         jobExtension: job.raw.jobExtension,
//         jobId: job.raw.jobId,
//         jobTitle: job.raw.jobTitle,
//         status: job.raw.status,
//       },
//     },
//   };
//   return newResource as RPUserModel;
// };

enum TimeType {
  START = 'Start',
  END = 'End',
}

const formatDate = (date: Date) => {
  return format(date, 'yyyy-MM-dd');
};

const errorBookingDate = (time: TimeType, date: Date | undefined) => {
  const conjunction = time === TimeType.START ? 'after' : 'before';
  IS_DEV &&
    console.log('Invalid start date or end date of booking. It should between the start date and end date of project');
  return window.utilities?.notification?.warning(
    `Project ${time} Date is ${formatDate(
      date as Date,
    )}, which is ${conjunction} the ${time.toLowerCase()} date of this booking. This will prevent the booked user from adding time to this project. Please amend the ${time.toLowerCase()} date of the project or the ${time.toLowerCase()} date of the booking.`,
  );
};

const millisecondsToHours = (milliseconds: number): number => {
  return milliseconds / 1000 / 60 / 60;
};

export const calculateHours = (startTime?: Date, endTime?: Date): string => {
  if (startTime && endTime) {
    const hours = millisecondsToHours(Number(endTime) - Number(startTime));
    return hours < 0 ? hours.toFixed(2) : (hours - Math.floor(hours / 24) * 24).toFixed(2);
  }
  return '0.00';
};

export const recalculateEndHours = (startTime: Date, hours: number): Date => {
  const hour = hours.toString().split('.')[0] ?? 0;
  const minute = (hours - Number(hour)) * 60;
  const newDate = addHours(startTime, Number(hour));
  return addMinutes(newDate, Number(minute));
};

const EditForm = (props: EditFormProps): ReactElement => {
  const state = useResourcePlannerState();
  const dispatch = useResourcePlannerDispatch();
  const {
    onSaveBookingFail,
    currentUser,
    primaryClient,
    allOfficesIds,
    onSaveSuccess,
    schedulerInstance,
    isNonChargeTime,
  } = props || {};
  const {
    editBooking,
    bookingPermissions,
    newBookingType,
    isEditFormVisible,
    isNew = true,
    eventRecord,
    PulseEditorProps = {},
  } = state;

  const [processingForm, setProcessingForm] = useState(false);
  const [resourceCalendar, setResourceCalendar] = useState<CalendarModel>();
  const [projectStartDate, setProjectStartDate] = useState<Date>();
  const [projectEndDate, setProjectEndDate] = useState<Date>();
  const [isLoading, setIsLoading] = useState(true);
  const [isOverbook, setIsOverbook] = useState<boolean | undefined>(undefined);
  const [isProcessingOverbooking, setIsProcessingOverbooking] = useState<boolean>(false);
  const [listOverbookedHoursDate, setListOverbookedHoursDate] = useState<OverBookingList[] | []>([]);
  const [openWarningModal, setOpenWarningModal] = useState<boolean>(false);
  const [confirmSaveBooking, setConfirmSaveBooking] = useState<boolean>(false);

  useEffect(() => {
    if (isNew) {
      setIsLoading(false);
      return;
    }

    editBooking && setIsLoading(false);
  }, []);

  const reInitializeState = () => {
    setProcessingForm(false);
  };

  const onClose: DrawerProps['onClose'] = (event, reason) => {
    if (reason && processingForm) {
      return;
    }
    closeDrawer();
  };

  const closeDrawer = (): void => {
    dispatch({
      type: Actions.hideEditForm,
    });
    reInitializeState();
  };

  const deleteBooking = (): void => {
    dispatch({
      type: Actions.showDeleteBookingModal,
      payload: {
        eventRecord,
      },
    });
  };

  /**
   * Set Project start day and end day for allowed booking
   */
  const setProjectDate = (start: Date, end: Date) => {
    setProjectStartDate(start);
    setProjectEndDate(end);
  };

  const onSaveBookingForm = values => {
    /**
     * Need to delete this to prevent duration from
     * setting the wrong end date
     */
    delete values.duration;

    if (isOverbook) {
      setOpenWarningModal(true);
      return;
    }

    const savingBookingUrl = `${PULSE_LOCAL_URL}/v2/api/bookings`;

    const {
      bookingId,
      timesheetActivity,
      bookedUser,
      description,
      endDate,
      priority,
      hoursPerDay,
      linkedTasks,
      job,
      requestedBy,
      startDate,
      bookingStatus,
      bookingCustomColor,
    } = values;

    const formattedEndDate = format(endDate as Date, "yyyy-MM-dd'T'HH:mm:ss");
    const formattedStartDate = format(startDate as Date, "yyyy-MM-dd'T'HH:mm:ss");

    const booking = {
      data: {
        type: 'bookings',
        attributes: {
          activity: timesheetActivity.value,
          bookedUser: bookedUser.value,
          description: description,
          endDate: formattedEndDate,
          escalate: priority || 'standard',
          hoursPerDay: hoursPerDay,
          linkBooking: linkedTasks?.map(task => task.value),
          project: job.value,
          requestedBy: requestedBy?.map(user => user.value),
          startDate: formattedStartDate,
          status: bookingStatus.value,
          color: bookingCustomColor?.name ?? null,
        },
      },
    };

    if (!isNew) {
      booking.data['id'] = bookingId?.toString();
    }

    /**
     * The booking start date and end date should be in the range of selected project start date and end date.
     * If project start/end date is null/empty, we still accept it.
     */
    const isBookingStartAfterProject =
      !isValidDate(projectStartDate) ||
      isAfter(startDate, projectStartDate as Date) ||
      isSameDay(startDate, projectStartDate as Date);
    const isBookingEndBeforeProject =
      !isValidDate(projectEndDate) ||
      isBefore(endDate, projectEndDate as Date) ||
      isSameDay(endDate, projectEndDate as Date);

    const isValidProjectDate = isBookingStartAfterProject && isBookingEndBeforeProject;
    !isBookingStartAfterProject && errorBookingDate(TimeType.START, projectStartDate);
    !isBookingEndBeforeProject && errorBookingDate(TimeType.END, projectEndDate);

    if (isValidProjectDate) {
      setProcessingForm(true);
      v2Endpoint
        .request({
          method: isNew ? 'POST' : 'PATCH',
          url: isNew ? savingBookingUrl : `${savingBookingUrl}/${bookingId}`,
          data: booking,
          headers: {
            'Content-Type': 'application/vnd.api+json',
            Accept: 'application/vnd.api+json',
          },
          params: REMOVE_LINK_PARAMS,
          paramsSerializer: params => {
            return qs.stringify(params);
          },
        })
        .then(async ({ data: booking }) => {
          setProcessingForm(false);
          dispatch({
            type: Actions.hideEditForm,
          });

          const { updatedAt, bookingId, createdAt, draggable, editable } = booking.data.attributes;
          const updatedBy = currentUser;
          // this is resource id when group by with user
          let resourceId = getResourceUserid(values);

          /**
           * @todo add locked='y', draggable=false, resizeable=false for
           * request booking
           */

          if (isNew) {
            const newBooking = new RPBookingModel({
              ...values,
              bookingId,
              createdAt,
              job: {
                brandId: values?.job?.raw?.brandId,
                brandTitle: values?.job?.raw?.brandTitle,
                jobExtension: values?.job?.raw?.jobExtension,
                value: values?.job?.value,
                label: values?.job?.label,
                isNC: values?.job?.raw?.isNC ?? isNonChargeTime,
                raw: {
                  clientId: values?.job?.raw?.clientId,
                  isAgresso: values?.job?.raw?.isAgresso,
                },
              },
              resourceId: values.bookedUser.value,
              showDescription: true,
              updatedAt,
              updatedBy,
              draggable,
              editable,
            });

            // newBooking.weekends = getEventsWeekends(newBooking, schedulerInstance);

            if (state.columnsGroupBy === GROUP_BY.projects) {
              // this is resource id when group by with user project
              resourceId = generateCompoundUserJobId(values);
            }

            /*TODO: commenting out for now. Will handle it on phase 4
            updateOverbooking(bookedUser.value, state);
            */
            onSaveSuccess && onSaveSuccess(newBooking, values, resourceId);

            window.utilities?.notification?.success('Booking created');
            schedulerInstance?.refreshRows();
            return;
          }

          /** If an existing eventRecord is being edited */
          if (eventRecord && schedulerInstance) {
            if (state.columnsGroupBy === GROUP_BY.projects) {
              const updatedEventRecord: RPBookingModelConfig = {
                ...values,
                bookedUser: {
                  ...values.bookedUser,
                  value: resourceId,
                },
                updatedBy,
                updatedAt,
                resourceId,
              };

              eventRecord.set(updatedEventRecord, null);

              // eventRecord.weekends = getEventsWeekends(eventRecord, schedulerInstance);
              return;
            }

            eventRecord.set(
              {
                ...values,
                updatedBy,
                updatedAt,
                resourceId: bookedUser.value,
              },
              null,
            );

            // eventRecord.weekends = getEventsWeekends(eventRecord, schedulerInstance);

            window.utilities?.notification?.success(isNew ? 'Booking created' : 'Booking updated');
            schedulerInstance?.refreshRows();
            /*TODO: commenting out for now. Will handle it on phase 4
            updateOverbooking(bookedUser.value, state);
            */
          }
        })
        .catch(error => {
          const fallbackErrorMessage = isNew ? 'Creating booking failed' : 'Updating booking failed';
          const errorMessage = error?.response?.data?.errors[0]?.detail || fallbackErrorMessage;
          window.utilities?.notification?.warning(errorMessage);
          onSaveBookingFail && onSaveBookingFail(error);
          IS_DEV && console.log(error);
        });
    }

    /**
     * @todo
     *
     * Send a request for the booked user id
     * with the date range of booking being
     * edited or created to retrieve the new
     * overbooked data
     */
  };

  const getTimeSheetActivityDefault = (bookedUser?: RPBookingModel['bookedUser']) =>
    bookedUser?.raw?.defaultTimesheetActivity?.timesheetactivityid
      ? {
          value: `${bookedUser?.raw?.defaultTimesheetActivity?.timesheetactivityid}`,
          label: bookedUser?.raw?.defaultTimesheetActivity?.name,
        }
      : undefined;

  const {
    bookedUser,
    bookingStatus,
    bookingId,
    description,
    endDate,
    eventColor,
    hoursPerDay,
    job,
    linkedTasks,
    priority,
    ptoRequestId,
    reportedBy,
    requestedBy,
    startDate,
    timesheetActivity,
    totalHours,
    updatedAt,
    bookingCustomColor,
    isCustomBookingColor,
  } = editBooking as Partial<RPBookingModel>;
  const typedEndDate = validBookingDate(endDate, resourceCalendar);
  const typedStartDate = validBookingDate(startDate, resourceCalendar);

  if (!typedEndDate || !typedStartDate) {
    window?.utilities?.notification.danger('Invalid start or end date. Formik failed.');
    throw new Error('Invalid start or end date.');
  }

  const [reInitialValues, setReInitialValues] = useState({
    job,
    bookingTimesheetActivity: timesheetActivity || getTimeSheetActivityDefault(bookedUser),
  });

  const handleSetReInitialValues = (timesheetActivity: OptionType, job: OptionType) => {
    setReInitialValues({
      bookingTimesheetActivity: timesheetActivity,
      job,
    });
  };

  return (
    <Formik
      initialValues={{
        bookedUser,
        bookingId,
        bookingStatus,
        description,
        endDate: typedEndDate,
        eventColor,
        hoursPerDay,
        job: reInitialValues?.job,
        linkedTasks,
        priority,
        ptoRequestId,
        reportedBy,
        requestedBy,
        startDate: typedStartDate,
        timesheetActivity: reInitialValues?.bookingTimesheetActivity,
        totalHours,
        updatedAt,
        bookingCustomColor,
        isCustomBookingColor,
      }}
      validationSchema={EditFormValidationSchema}
      enableReinitialize
      onSubmit={values => onSaveBookingForm(values)}
    >
      {formikProps => {
        const { isValid, values, setFieldValue, setValues, isSubmitting } = formikProps;

        const isEscalated = values.priority === 'escalated';
        const isRequestBooking = newBookingType === EditBookingType.REQUEST;

        const handleConfirmBooking = () => {
          setIsOverbook(false);
          setOpenWarningModal(false);
          setConfirmSaveBooking(true);
        };

        const handleModifyBooking = () => {
          setOpenWarningModal(false);
        };

        useEffect(() => {
          if (confirmSaveBooking) {
            onSaveBookingForm(values);
          }
        }, [confirmSaveBooking]);

        useEffect(() => {
          if (
            values.bookedUser &&
            values.job &&
            !values.bookedUser?.raw?.isAgresso &&
            !values.job?.isAgresso &&
            !values?.timesheetActivity
          ) {
            const defaultTimesheetActivity = values.bookedUser?.raw?.defaultTimesheetActivity;
            defaultTimesheetActivity &&
              setFieldValue('timesheetActivity', {
                label: defaultTimesheetActivity?.name,
                value: defaultTimesheetActivity?.timesheetactivityid,
              });
          }
        }, [values.bookedUser?.value, values.job?.value]);

        /**
         * Get holidays by booked user
         */
        useEffect(() => {
          const clientId = values.bookedUser?.raw?.primaryClient?.clientid;
          if (!clientId) {
            return;
          }
          const params = {
            filter: {
              client_id: [clientId],
            },
            jq:
              '( if (.data | length > 0) then .data as $data | .included as $included | $included[] | select(.type == "clients") as $client | .attributes | { id: (.clientid | tostring), name: .company, intervals: [ $data[] | select(.type == "holidays" and .relationships.client.data.id == $client.id) | .relationships.holidayDates.data[] as $holidayDate | $holidayDate | ( $included[] | select(.type == "holidayDates" and .id == $holidayDate.id) ) | .attributes | { isWorking: false, startDate: .date, endDate: .date } ] } else { intervals: [] } end )',
            include: 'holidayDates,client',
          };

          const queryString = qs.stringify(params, { encode: false });

          v2Endpoint
            .get(`${PULSE_LOCAL_URL}/v2/api/holidays?${queryString}`)
            .then(({ data }) => {
              data.intervals = data.intervals?.map(interval => {
                if (interval.startDate) {
                  interval.startDate = startOfDay(parseISO(interval.startDate));
                }
                if (interval.endDate) {
                  interval.endDate = startOfDay(addDays(parseISO(interval.endDate), 1));
                }
                return interval;
              });
              setResourceCalendar(data);
            })
            .catch(error => {
              window.utilities?.notification?.warning('Fetch holidays failed');
              IS_DEV && console.log(error);
            });
        }, [values.bookedUser?.value]);

        useEffect(() => {
          const bookingStartDate = validBookingDate(values.startDate, resourceCalendar);
          const bookingEndDate = validBookingDate(values.endDate, resourceCalendar);

          if (!bookingEndDate || !bookingStartDate) {
            throw new Error('bookingEndDate or bookingEndDate are false.');
          }

          setValues({
            ...values,
            startDate: bookingStartDate,
            endDate: bookingEndDate,
            totalHours: calculateTotalHours({
              startDate: bookingStartDate,
              endDate: bookingEndDate,
              hoursPerDay: values.hoursPerDay || DEFAULT_MAX_HOURS,
              calendar: resourceCalendar,
            }),
          });
        }, [resourceCalendar]);

        const onChangeEscalated = (): void => {
          setFieldValue('isCustomBookingColor', !!(bookingCustomColor && isEscalated));
          setFieldValue('priority', isEscalated ? 'standard' : 'escalated');
        };

        useEffect(() => {
          if (values.bookedUser?.value && isValidDate(values.endDate) && isValidDate(values.startDate)) {
            sourceOverBooking = CancelToken.source();
            setIsProcessingOverbooking(true);
            v2Endpoint
              .request({
                method: 'POST',
                url: `${PULSE_LOCAL_URL}/v2/api/users/overbook`,
                data: {
                  user_id: values.bookedUser?.value,
                  start_date: formatISO(values.startDate),
                  end_date: formatISO(values.endDate),
                  requested_hours: Number(values.hoursPerDay),
                  booking_id: isNew ? '' : values.bookingId,
                },
                headers: {
                  'Content-Type': 'application/vnd.api+json',
                  Accept: 'application/vnd.api+json',
                },
                cancelToken: sourceOverBooking.token,
              })
              .then(res => {
                setIsOverbook(res.data.overbooked);
                setListOverbookedHoursDate(res.data.overbooked_days);
                setIsProcessingOverbooking(false);
              })
              .catch(err => {
                if (!axios.isCancel(err)) {
                  window.utilities?.notification.danger(err);
                }
              });
            return () => {
              sourceOverBooking.cancel?.();
            };
          }
        }, [`${values.startDate}`, `${values.endDate}`, values.hoursPerDay, values.bookedUser?.value]);

        /**
         * This has changed now to allow unspecified time
         */
        useEffect(() => {
          /**
           * Get available project start and end date for booking check
           * */
          if (values) {
            const projectStart = parseISO(values.job?.raw?.projectedStartDate);
            const projectEnd = parseISO(values.job?.raw?.projectedEndDate);
            setProjectDate(projectStart, projectEnd);
          }
        }, []);

        const tabData: PulseTabsProps['tabData'] = useMemo(() => {
          return [
            {
              id: 'booking_details',
              tabLabel: 'BOOKING DETAILS',
              TabProps: {
                classes: {
                  selected: styles['tabs__header--selected'],
                },
              },
              tabContent: (
                <BookingDetails
                  formikProps={formikProps}
                  resourceCalendar={resourceCalendar}
                  isNew={isNew}
                  isNonChargeTime={isNonChargeTime}
                  isOverbook={isOverbook}
                  overBookingList={listOverbookedHoursDate}
                  isProcessingOverbooking={isProcessingOverbooking}
                  primaryClient={primaryClient}
                  allOfficesIds={allOfficesIds}
                  PulseEditorProps={PulseEditorProps}
                  handleSetReInitialValues={handleSetReInitialValues}
                />
              ),
              TabPanelProps: {
                classes: {
                  root: styles['booking-details__body'],
                },
              },
            },
            {
              id: 'linked_tasks',
              tabLabel: `LINKED TASKS ${
                formikProps.values.linkedTasks && formikProps.values.linkedTasks?.length > 0
                  ? `(${formikProps.values.linkedTasks.length})`
                  : ``
              }`,
              tabContent: <LinkedTasks formikProps={formikProps} />,
              TabProps: {
                classes: {
                  selected: styles['tabs__header--selected'],
                },
              },
              TabPanelProps: {
                classes: {
                  root: styles['booking-linked-tasks__body'],
                },
              },
            },
            {
              id: 'history',
              tabLabel: 'HISTORY',
              TabProps: {
                classes: {
                  selected: styles['tabs__header--selected'],
                },
              },
              tabContent: <BookingHistory bookingId={bookingId} />,
              TabPanelProps: {
                classes: {
                  root: styles['booking-history__body'],
                },
              },
            },
          ];
        }, [formikProps.values, isOverbook, isProcessingOverbooking]);
        return (
          <Form>
            <PulseDrawer
              closeDrawerHandler={closeDrawer}
              DrawerProps={{
                classes: {
                  root: styles['edit-form__DrawerRoot'],
                  paperAnchorRight: styles['edit-form__DrawerPaperAnchorRight'],
                },
                anchor: 'right',
                onClose: onClose,
                open: isEditFormVisible,
              }}
              PulseXhrRequestStatusProps={{
                pendingMessage: 'Saving booking',
                successMessage: 'Saved booking',
                errorMessage: 'Error occurs',
                status: RequestStatus.pending,
              }}
              loading={processingForm}
              ScrollbarProps={{
                ScrollbarProps: {
                  className: styles['edit-form__scrollbar-content'],
                },
                classes: {
                  content: styles['edit-form__scrollbar-content'],
                },
              }}
              PulseModalHeaderProps={{
                headerColor: values?.isCustomBookingColor
                  ? getEventColorCustom(values?.bookingCustomColor, values?.bookingStatus?.value)
                  : '',
                classes: {
                  root: clsx(
                    styles['edit-form__modalHeaderRoot'],
                    `event-color-${getEventColor(values as RPBookingModel)}`,
                  ),
                  titleContainer: styles['edit-form__titleContainer'],
                  titleContainerText: styles['edit-form__titleContainerText'],
                  titlePrimary: styles['edit-form__title'],
                  titleSecondary: styles['edit-form__secondaryTitle'],
                  additionalContent: styles['edit-form__additionalContent'],
                  closeBtn: styles['edit-form__closeBtn'],
                },
                primaryText: '',
                secondaryText: (
                  <div className={styles['edit-form__header']}>
                    <div className={styles['edit-form__info']}>
                      {eventRecord?.job && (
                        <>
                          <h2 className={styles['edit-form__info--jobTitle']}>{eventRecord?.job?.label}</h2>
                          <p className={styles['edit-form__info--subInfo']}>
                            [{eventRecord?.job?.jobExtension}] {eventRecord?.bookedUser?.raw?.client} /{' '}
                            {eventRecord?.job?.brandTitle}
                          </p>
                        </>
                      )}
                    </div>
                    <div className={styles['edit-form__modalHeaderAdditionalContent']}>
                      {
                        /**
                         *  Only non-charge or normal booking can be escalated
                         */
                        (values.job?.isNC || !values.ptoRequestId) && (
                          <Tippy {...generateTippyProps(values.priority)}>
                            <PulseToggleButton
                              disabled={!bookingPermissions.canEditBooking}
                              label={isEscalated ? 'de-escalate' : 'escalate'}
                              classes={[
                                clsx([
                                  styles['edit-form__escalatedBtn'],
                                  isEscalated && styles['edit-form__escalatedBtn--active'],
                                ]),
                              ]}
                              iconClasses={{ icon: 'fal fa-fire-alt' }}
                              icon
                              on={isEscalated}
                              onClick={onChangeEscalated}
                              variant={ButtonVariants.text}
                            />
                          </Tippy>
                        )
                      }
                    </div>
                    {!isNew && (
                      <div className={styles['edit-form__projectLink']}>
                        <a
                          href={`passport/${values?.job?.value}`}
                          target="_blank"
                          rel="noreferrer"
                          data-toggle="tooltip"
                          title={`${values?.job?.value}`}
                          className={styles['edit-form_projectTxt']}
                        >
                          Go to project
                        </a>
                        <Tippy {...generateTippyPropsForProjectDetails({ job: values.job, bookingId, requestedBy })}>
                          <i className={clsx([styles['edit-form_projectTxt--icon'], 'fal fa-info-circle'])}></i>
                        </Tippy>
                      </div>
                    )}
                  </div>
                ),
                PulseIconButtonProps: {
                  tooltip: '',
                },
              }}
              PulseModalFooterProps={{
                classes: {
                  root: [styles['edit-form__footerRoot']],
                },
                confirmComponent: (
                  <div className={styles['confirm-component__ctn']}>
                    <PulseButtonBase
                      disabled={!isValid || isSubmitting}
                      classes={[styles['edit-form__button']]}
                      label={!isRequestBooking ? 'Save booking' : 'Request'}
                      color={Colors.success}
                      icon
                      type={'submit'}
                      iconClasses={{ icon: clsx('fal fa-save', styles['edit-form__buttonIcon']) }}
                      onClick={() => onSaveBookingForm(values)}
                    />
                  </div>
                ),
                cancelComponent: (
                  <PulseButtonBase
                    classes={[styles['edit-form__button']]}
                    color={Colors.default}
                    label={isNew ? 'Cancel' : 'Delete booking'}
                    icon
                    iconClasses={{
                      icon: clsx('fal fa-trash-alt', styles['edit-form__buttonIcon']),
                    }}
                    onClick={isNew ? closeDrawer : deleteBooking}
                  />
                ),
              }}
            >
              {isLoading ? (
                <div className={clsx([styles['edit_form__loader']])}>
                  <PCSSpinner
                    classes={{ root: [clsx(styles['pulse-loader__spinner'])] }}
                    variant={PCSSpinnerVariants.circularSpinner}
                  />
                  Loading...
                </div>
              ) : (
                <>
                  <div className={styles['edit-form__body']}>
                    <PulseTabs
                      classes={{
                        root: styles['edit-form__tabs'],
                      }}
                      TabsProps={{
                        classes: {
                          root: styles['tabs__header'],
                        },
                      }}
                      tabData={tabData}
                      theme={PulseTabsTheme.light}
                    />
                  </div>
                </>
              )}
            </PulseDrawer>
            {
              <ResourceOverbookedModal
                isOpen={openWarningModal}
                onCancel={handleModifyBooking}
                onConfirm={handleConfirmBooking}
                userInfo={values.bookedUser}
                overBookingList={listOverbookedHoursDate}
              />
            }
          </Form>
        );
      }}
    </Formik>
  );
};

export default React.memo(EditForm);
