import React, { Ref, useRef, forwardRef, useEffect, useMemo } from 'react';
import '@bryntum/core-thin/core.stockholm.css';
import '@bryntum/grid-thin/grid.stockholm.css';
import styles from './pulse-bryntum-grid.module.scss';
import { PulseBryntumGridType, FeatureGridProps, GridColumn } from './pulse-bryntum-grid.type';
import isFunction from 'lodash/isFunction';
import { BryntumGrid } from '@bryntum/grid-react-thin';
import { Grid, GridConfig, Column, ColumnStore } from '@bryntum/grid-thin';
import clsx from 'clsx';

import {
  AjaxStore,
  ButtonConfig,
  PanelConfig,
  WidgetConfig,
  ToolbarConfig,
  ContainerItemConfig,
  Widget,
  Model,
} from '@bryntum/core-thin';
import mergeRefs from 'react-merge-refs';
import { isV3Component } from 'components/v3/helpers/v3-components.helper';

const DEFAULT_ROW_HEIGHT = 32;
const DEFAULT_ROW_WIDTH = 32;
const DEFAULT_CONFIG: FeatureGridProps = {
  autoHeight: true,
  minRowHeight: DEFAULT_ROW_HEIGHT,
  minRowWidth: DEFAULT_ROW_WIDTH,
  sortFeature: false,
  cellEditFeature: false,
  filterFeature: false,
  groupFeature: false,
};

enum SHOW_SELECTED_BUTTON_TEXT {
  selectedOnly = 'Show All Selected Items',
  all = 'Show all items',
}

enum CHECKED_ACTION {
  selected = 'select',
  deselect = 'deselect',
}

enum SELECTION_EVENT_NAME {
  beforeSelectionChange = 'beforeSelectionChange',
  selectionChange = 'selectionChange',
}

/**
 * Function to return the string to style the
 * selected count
 * @param count seletectRecords length
 * @returns html string
 */
const selectedCountString = (count: number): string => {
  return `<span>${count} selected</span>`;
};

/**
 * Filters the bbar item to get the instance of the
 * widget used so we can manipulate them.
 * @param items bbar items
 * @param ref the items key nam
 * @returns
 */
const filterBBaritems = (items: [WidgetConfig, PanelConfig, ButtonConfig], ref: string) => {
  return items.filter(item => item.ref === ref).shift();
};

const { localStorage } = window;

const PulseBryntumGrid = (props: PulseBryntumGridType, ref: Ref<any>) => {
  const {
    selectable = false,
    paginate = true,
    ajaxStoreConfig,
    gridProps,
    onSelectionChange,
    classes,
    localStorageConfigsKey,
    designVariant,
  } = props;

  const { listeners, bbar, selectionMode: selectionModeProps, columns: gridColumns = {}, ...restGridProps } =
    gridProps || {};

  const gridInstanceRef: Ref<any> = useRef(null);
  const bryntumGridClasses = clsx(styles['bryntumGrid__root'], styles['bryntumGrid__root--v3'], classes?.root);

  /** @var showSelectedOnly - variable to keep state of toggle button */
  let showSelectedOnly = false;
  /** @var checkDeselect - check select remove item */
  let checkDeselect = true;

  const selectedItemsStore = new AjaxStore({
    id: 'selectedItems',
    data: [],
  });

  let ajaxStore: GridConfig['store'] = {};

  if (ajaxStoreConfig && gridProps) {
    ajaxStore = new AjaxStore({
      ...ajaxStoreConfig,
      id: 'ajaxStore',
    });
    delete gridProps.store;
  }

  const handleSelectedFilter = () => {
    checkDeselect = false;
    /**
     * Apply the filter to the grid
     */
    const gridInstance: Grid = gridInstanceRef.current.instance;
    if (!showSelectedOnly) {
      gridInstance.store = selectedItemsStore;
      gridInstance.selectAll();
    } else {
      gridInstance.store = ajaxStore;
      gridInstance.selectedRecords = selectedItemsStore.allRecords;
    }

    /**
     * Grab an instance of the show selected toggle button
     */
    const toggleButtonInstance = filterBBaritems(
      gridInstance.bbar.items as [WidgetConfig, PanelConfig, ButtonConfig],
      'filterSelectAll',
    );

    if (toggleButtonInstance) {
      /** Toggle the text for the button */
      toggleButtonInstance.html = `<div class=${styles['filterSelectedBtn__textContent']}>${
        showSelectedOnly ? SHOW_SELECTED_BUTTON_TEXT.selectedOnly : SHOW_SELECTED_BUTTON_TEXT.all
      }</div>`;
    }

    checkDeselect = true;
    showSelectedOnly = !showSelectedOnly;
  };

  let pagingToolbar: Partial<ToolbarConfig & { type: string }> | undefined;
  if (paginate) {
    pagingToolbar = {
      ...bbar,
      overflow: 'menu',
      cls: styles['pagingToolbar__root'],
      itemCls: styles['pagingToolbar__item'],
      type: 'pagingtoolbar' as ToolbarConfig['type'],
      items: {
        ...bbar,
      } as Record<string, Partial<ContainerItemConfig> | boolean | null> | Partial<ContainerItemConfig>[] | Widget[],
    };
  }

  if (selectable && pagingToolbar) {
    pagingToolbar.items = {
      ...pagingToolbar.items,
      filterSelectAll: {
        type: 'button',
        text: SHOW_SELECTED_BUTTON_TEXT.selectedOnly,
        cls: clsx(
          styles['toolbarPagination__filterSelectedBtn'],
          styles['toolbarPagination__filterSelectedBtn--hidden'],
        ),
        onClick: handleSelectedFilter,
      },
      selectedCountSection: {
        type: 'panel',
        content: selectedCountString(0),
        cls: styles['toolbarPagination__selectCount'],
      },
    };
  }

  const selectionMode = {
    ...selectionModeProps,
    row: selectable,
    checkbox: selectable,
    multiSelect: selectable,
    showCheckAll: selectable,
    preserveSelectionOnPageChange: selectable,
  };

  const handleSelectionChange = selectionChange => {
    /**
     * @todo This changes is for fixing the issue of bryntum in version 5.3.2,
     * will need to remove if bryntum updates the fix for it!
     */
    const isSelectionChange = selectionChange.eventName !== SELECTION_EVENT_NAME.selectionChange;
    const isCheckAll = selectionChange.source.data.length !== selectionChange.selected.length;
    if (selectionChange.mode !== 'row' || (isSelectionChange && isCheckAll) || (!isSelectionChange && !isCheckAll))
      return;

    const gridInstance: Grid = gridInstanceRef.current?.instance;

    if (selectionChange.action === CHECKED_ACTION.selected) {
      selectedItemsStore.add(selectionChange.selected);
      selectedItemsStore.remove(selectionChange.deselected);
    }
    if (selectionChange.action === CHECKED_ACTION.deselect && checkDeselect) {
      selectedItemsStore.remove(selectionChange.deselected);
    }

    let selectedRecordsLength = gridInstance.selectedRecords.length;

    if (selectionChange.eventName === SELECTION_EVENT_NAME.beforeSelectionChange) {
      const selectedItemsList: Model[] = selectedItemsStore.allRecords.filter(
        item => !(gridInstance.selectedRecords as Model[]).includes(item),
      );
      selectedRecordsLength = selectedItemsStore.allRecords.length;
      (gridInstance.selectedRecords as Model[]).push(...selectedItemsList);
    }

    if (gridInstance.bbar) {
      const filterSelectAllBtnEl = document.querySelector(`.${styles['toolbarPagination__filterSelectedBtn']}`);

      const selectedCountSectionInstance = filterBBaritems(
        gridInstance.bbar.items as [WidgetConfig, PanelConfig, ButtonConfig],
        'selectedCountSection',
      );

      /**
       * Show or hide the toggle button
       */
      if (filterSelectAllBtnEl) {
        if (selectedRecordsLength || showSelectedOnly) {
          filterSelectAllBtnEl.classList.remove(styles['toolbarPagination__filterSelectedBtn--hidden']);
        } else {
          filterSelectAllBtnEl.classList.add(styles['toolbarPagination__filterSelectedBtn--hidden']);
        }
      }
      selectedCountSectionInstance &&
        (selectedCountSectionInstance.content = selectedCountString(selectedRecordsLength));
    }

    if (selectable && isFunction(onSelectionChange)) {
      onSelectionChange(selectionChange);
    }
  };

  const gridConfigs = useMemo(() => {
    let columns = gridColumns as GridColumn[];

    if (localStorageConfigsKey) {
      const columnsConfigsStorage = localStorage.getItem(localStorageConfigsKey);
      columns = columns.map<GridColumn>(column => {
        const columnConfigs: GridColumn =
          columnsConfigsStorage && column.field && JSON.parse(columnsConfigsStorage)[column.field];
        return {
          ...column,
          ...columnConfigs,
        };
      });
    }

    return {
      ...restGridProps,
      columns,
    };
  }, [gridColumns]);

  useEffect(() => {
    const gridInstance: Grid = gridInstanceRef.current?.instance;
    const gridColumns = gridInstance?.columns as ColumnStore;
    if (gridColumns && localStorageConfigsKey) {
      gridColumns.on('change', () => {
        const columns = gridColumns.records as (Column & GridColumn)[];
        const columnsStorage = columns.reduce<GridColumn>(
          (result, column) => ({
            ...result,
            [column.field || column.id]: {
              width: column.width,
              hidden: column.hidden,
            },
          }),
          {},
        );
        localStorage.setItem(localStorageConfigsKey, JSON.stringify(columnsStorage));
      });
    }
  }, []);

  return (
    <div className={bryntumGridClasses} data-testid={'pulse-bryntum-grid'}>
      <BryntumGrid
        {...DEFAULT_CONFIG}
        selectionMode={selectionMode}
        ref={mergeRefs([ref, gridInstanceRef])}
        listeners={{
          selectionChange: handleSelectionChange,
          beforeSelectionChange: handleSelectionChange,
          ...listeners,
        }}
        bbar={pagingToolbar}
        store={ajaxStore}
        {...gridConfigs}
      />
    </div>
  );
};
PulseBryntumGrid.displayName = 'PulseBryntumGrid';
export default forwardRef(PulseBryntumGrid);
