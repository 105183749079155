import PulseColorPicker from 'components/pulse-color-picker/pulse-color-picker';
import { useFormikContext } from 'formik';
import React, { ReactElement } from 'react';
import styles from './booking-color.module.scss';
import PulseColorPickerData from 'components/pulse-color-picker/pulse-color-picker-colors.json';
import RPBookingModel from 'components/pulse-resource-planner/models/rp-booking-model';

const defaultBookingColor = PulseColorPickerData.find(color => color?.default);
const BookingColor = (): ReactElement => {
  const { values, setValues } = useFormikContext<Partial<RPBookingModel>>();
  const isEscalated = values.priority === 'escalated';

  const onChangeColor = color => {
    setValues({
      ...values,
      bookingCustomColor: color,
      isCustomBookingColor: true,
    });
  };

  return (
    <PulseColorPicker
      disabled={isEscalated}
      PulseColorSwatchProps={{
        selectedColor: values?.bookingCustomColor ?? defaultBookingColor,
        onSelect: onChangeColor,
      }}
      classes={{
        root: styles['booking-color'],
        label: styles['booking-color__label'],
        button: styles['booking-color__button'],
      }}
      PulseMenuProps={{
        TippyProps: {
          placement: 'bottom-start',
        },
      }}
      variant="colorPickerV2"
    />
  );
};

export default BookingColor;
