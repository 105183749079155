import { PulsePowerSearchValueType } from 'components/pulse-power-search/pulse-power-search-types';
import { DeeplinkFiltersMeta } from 'HOC/deeplink-types';
import isFunction from 'lodash/isFunction';
import capitalize from 'lodash/capitalize';
import { PulsePowerSearchAllowedResource } from 'pulse-api/power-search/power-search-types';
import { ResourcePlannerDeeplink } from '../pulse-resource-planner-types';
import { ResourcePlannerState } from '../reducers/resource-planner-reducer';
import { TJsonaModel } from 'jsona/lib/JsonaTypes';
import RPBookingModel from '../models/rp-booking-model';
import { ResourcePlannerResponsesParamType, ResourcePlannerResponsesType } from './resource-planner-transformers-types';
import uniqBy from 'lodash/uniqBy';
import flatten from 'lodash/flatten';
import map from 'lodash/map';
import { getPulseCustomColorData } from '../utils';

/**
 * Utility function to map DeeplinkService response to
 * Resource Planner selectedFilters data structure used
 * for the power search
 * @param data
 * @returns
 */
export const ResourcePlannerDeepLinkTransformer = (
  data: DeeplinkFiltersMeta<ResourcePlannerDeeplink>,
): ResourcePlannerState['selectedFilters'] => {
  const selectedFilters: PulsePowerSearchValueType[] = [];
  for (const key in data) {
    let type = key;
    let valueWithType = false;
    let labelFn;
    switch (key) {
      case 'booking.startdate':
        type = key;
        break;
      case PulsePowerSearchAllowedResource.Jobs.requestKey:
        type = PulsePowerSearchAllowedResource.Jobs.returnedType;
        labelFn = value => {
          return `[${value.jobextension}] ${value.jobtitle}`;
        };
        break;
      case PulsePowerSearchAllowedResource.AssignedUser.requestKey:
        type = PulsePowerSearchAllowedResource.AssignedUser.returnedType;
        valueWithType = true;
        break;
      case PulsePowerSearchAllowedResource.Clients.customFacets?.UserOffices.requestKey:
        type = PulsePowerSearchAllowedResource.Clients.customFacets?.UserOffices.returnedType || 'userOffices';
        valueWithType = true;
        break;
      case PulsePowerSearchAllowedResource.Clients.customFacets?.JobOffices.requestKey:
        type = PulsePowerSearchAllowedResource.Clients.customFacets?.JobOffices.returnedType || 'jobOffices';
        valueWithType = true;
        break;
      case PulsePowerSearchAllowedResource.Requestor.requestKey:
        type = PulsePowerSearchAllowedResource.Requestor.returnedType;
        valueWithType = true;
        break;
      default:
        const deepLinkKey = key;
        for (const key in PulsePowerSearchAllowedResource) {
          if (deepLinkKey === PulsePowerSearchAllowedResource[key].requestKey) {
            type = PulsePowerSearchAllowedResource[key].returnedType;
          }
        }
        break;
    }

    if (key === 'booking.startdate') {
      selectedFilters.push({
        label: key,
        type,
        value: data[key],
      });
    } else if (key === 'booking.probability') {
      (data[key] as string[]).map((bookingStatus: string) => {
        const label = bookingStatus === 'workCompleted' ? 'Work Completed' : bookingStatus;
        const capitalizedLabel = capitalize(label);
        selectedFilters.push({
          label: capitalizedLabel,
          type,
          value: bookingStatus,
        });
      });
    } else if (key === PulsePowerSearchAllowedResource.JobStatus.requestKey) {
      (data[key] as string[]).map((jobStatus: string) => {
        const label = jobStatus.replace('_', ' ');
        const capitalizedLabel = capitalize(label);
        selectedFilters.push({
          label: capitalizedLabel,
          type,
          value: jobStatus,
        });
      });
    } else {
      for (const i in data[key]) {
        selectedFilters.push({
          label: isFunction(labelFn) ? labelFn(data[key][i]) : data[key][i],
          type,
          value: valueWithType ? `${type}${i}` : i,
        });
      }
    }
  }
  return selectedFilters;
};

export const transformersResponseGetBookings = (
  data: TJsonaModel | TJsonaModel[],
): Pick<ResourcePlannerResponsesType, 'events'> => {
  const eventsRows: RPBookingModel[] = data
    .map(user => {
      const {
        bookings = [],
        name,
        userid,
        is_agresso,
        maxHours,
        job: jobList,
        client,
        department,
        defaultTimeSheet,
        roles,
      } = user;
      const bookingsMapping = bookings.map(booking => {
        const {
          probability,
          description,
          locked,
          editable,
          endDate,
          hoursPerDay,
          bookingId,
          priority,
          ptoRequestId,
          assignedId,
          showDescription,
          startDate,
          totalHours,
          job = {},
          activity,
          ticket,
          requestedBy,
          color,
        } = booking;

        const { jobId, jobTitle, jobExtension, brandId, brandTitle, isNC, clientId, isAgresso } = job;

        const ticketMapping = ticket?.map(ticketItem => {
          const { title, ticketid, jobid, ticketUrl, ticketStatus, type, dueDate } = ticketItem;
          return {
            label: title,
            value: ticketid,
            jobid,
            taskUrl: ticketUrl,
            raw: {
              ticketStatus,
              ticketUrl,
              // ticketType missing include bookings.ticket.type
              ticketType: type,
              dueDate,
            },
          };
        });

        const requestByMapping = requestedBy?.map(item => ({
          label: item.name,
          value: item.userid,
          avatarUrl: item.avatarUrl,
        }));

        return {
          bookingStatus: {
            label: probability,
            value: probability,
          },
          description,
          locked,
          editable,
          endDate,
          hoursPerDay,
          bookingId,
          priority,
          ptoRequestId,
          resourceId: jobList ? `${assignedId}-${jobId}` : assignedId?.toString(),
          showDescription,
          startDate,
          totalHours,
          bookedUser: {
            label: name,
            value: userid,
            raw: {
              isAgresso: is_agresso,
              maxHours,
              client,
              departments: {
                department_id: department?.department_id ?? '',
                name: department?.name ?? '',
              },
              defaultTimesheetActivity: {
                timesheetactivityid: defaultTimeSheet?.id ?? '',
                name: defaultTimeSheet?.name ?? '',
              },
              roles: {
                role_id: roles?.role_id ?? '',
                name: roles?.name ?? '',
              },
            },
          },
          requestedBy: requestByMapping,
          job: {
            value: jobId,
            label: jobTitle,
            jobExtension: jobExtension,
            brandId,
            brandTitle,
            isNC,
            raw: {
              clientId,
              isAgresso,
            },
          },
          timesheetActivity: activity
            ? {
                value: activity.id,
                label: activity?.name,
              }
            : null,
          linkedTasks: ticketMapping,
          bookingCustomColor: color && getPulseCustomColorData(color),
          isCustomBookingColor: !!color,
        };
      });

      return bookingsMapping;
    })
    .flat();

  return {
    events: {
      rows: eventsRows,
    },
  };
};

export const transformersGetBookingsByProject = (
  data: TJsonaModel | TJsonaModel[],
): Pick<ResourcePlannerResponsesType, 'resources' | 'events'> => {
  const resourceRows = data
    .map(user => {
      const {
        id,
        name,
        maxHours,
        is_agresso: isAgresso,
        client,
        department,
        defaultTimeSheet,
        roles,
        activeClients,
        job,
      } = user;

      const resourceData = {
        id,
        name,
        maxHours,
        isAgresso,
        job: job || null,
        client,
        departments: {
          department_id: department?.department_id ?? '',
          name: department?.name ?? '',
        },
        defaultTimesheetActivity: {
          timesheetactivityid: defaultTimeSheet?.id ?? '',
          name: defaultTimeSheet?.name ?? '',
        },
        roles: {
          role_id: roles?.role_id ?? '',
          name: roles?.name ?? '',
        },
        // missing include users.activeClients
        calendar: activeClients?.clientid || null,
      };

      if (job) {
        const result = job.map(jobIitem => ({
          ...resourceData,
          id: `${id}-${jobIitem.id}`,
          job: jobIitem ? { id: jobIitem.id, type: jobIitem.type, attributes: jobIitem } : null,
        }));

        return uniqBy(result, 'id');
      } else {
        return resourceData;
      }
    })
    .flat();

  return {
    resources: {
      rows: resourceRows,
    },
    ...transformersResponseGetBookings(data as TJsonaModel[]),
  };
};

export const transformResponse = (data: ResourcePlannerResponsesParamType): ResourcePlannerResponsesType => {
  const { holidays, users } = data;
  const calendarRows = holidays.map(holiday => {
    const { holidayDates = [], id } = holiday;
    const intervals = holidayDates.map(holidayDate => ({
      isWorking: false,
      startDate: holidayDate.date,
      endDate: holidayDate.date,
    }));
    return {
      id: id,
      intervals,
    };
  });

  const timeRangeRows = flatten(
    holidays.map(holiday => {
      const { holidayId, cls, client, holidayDates = [] } = holiday;
      return holidayDates.map(holidayDate => ({
        startDate: holidayDate.date,
        endDate: holidayDate.date,
        holidayId,
        cls: `timerange--holiday ${cls}`,
        client,
      }));
    }),
  );

  return {
    project: {
      calendar: 'weekends',
    },
    calendars: {
      rows: [
        {
          id: 'weekends',
          name: 'Weekends',
          intervals: [
            {
              recurrentStartDate: 'on Sat at 0:00',
              recurrentEndDate: 'on Mon at 0:00',
              isWorking: false,
            },
          ],
        },
        ...calendarRows,
      ],
    },
    ...transformersGetBookingsByProject(users),
    resourceTimeRanges: {
      rows: [],
    },
    timeRanges: {
      rows: timeRangeRows,
    },
  };
};

export const transformResponseBatching = (currentValue: TJsonaModel): void => {
  if (currentValue) {
    map(currentValue, item => {
      if (item.type === 'users') {
        item.type = 'booking-users';
      }
      return item;
    });
  }
};
